import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import cn from 'clsx';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import { signUp } from '../../utils/contentProvider/function';
import LoginWithGoogle from '../../components/LoginWithGoogle';
import TrustedBy from '../../components/TrustedBy';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import 'styles/features.scss';
import NewFaq from '../../components/NewFaq/NewFaq';
const StaticImageReview = ({ location }) => {
  const [showVideoModal, setVideoModal] = useState(false);

  function toggleVideoModal() {
    setVideoModal(!showVideoModal);
  }

  function clickHandler(e) {
    if (e.target.id === 'demo-modal') setVideoModal(false);
  }

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  useEffect(() => {
    document.body.style.overflow = showVideoModal ? 'hidden' : 'auto';
  }, [showVideoModal]);

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Review Static Images | ruttl features"
            description="Effortlessly review & annotate static images with ruttl's website annotation tool. Add comments, create or delete versions and share with team or clients."
            url="https://ruttl.com/features/static-image-review/"
          />
          <div
            id="demo-modal"
            className={cn(['demo-modal', { show: showVideoModal }])}>
            <button
              type="button"
              className="button-unstyled"
              onClick={toggleVideoModal}>
              <img src="/assets/img/close.svg" alt="close" title="close" />
            </button>
            {showVideoModal && (
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/mVwShqUJmEI"
                frameBorder="0"
                title="ruttl demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <main className="features-styled-main">
            <section className="features-hero">
              <div className="container">
                <h1>Static image review</h1>
                <p>
                  Upload any static image or multiple versions of them and
                  comment on the same. Rename, share or delete image versions
                  with our fast website annotation tool.
                </p>

                <div
                  className="reveal-1 button-container"
                  style={{ margin: '30px 0' }}>
                  <LoginWithGoogle />

                  <div>
                    <a
                      className="button button-white"
                      href="https://web.ruttl.com/share/demo"
                      target="_blank"
                      rel="noreferrer noopener">
                      Try demo
                      <svg width="20" height="20" viewBox="0 0 512 512">
                        <path
                          fill="none"
                          stroke="#160647"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="48"
                          d="M268 112l144 144-144 144M392 256H100"></path>
                      </svg>
                    </a>
                  </div>
                </div>
                <TrustedBy />
              </div>
            </section>

            <div className="container">
              <div className="feature-banner">
                <button
                  type="button"
                  className="button button-transparent"
                  onClick={toggleVideoModal}>
                  <img
                    src="/assets/img/static-image-review.png"
                    alt="Static img reviewing youtube video banner"
                    title="Static img reviewing youtube video banner"
                    className="banner-thumb"
                  />
                  <img
                    className="play-button"
                    src="/assets/img/play.svg"
                    alt="play icon designed by brucira"
                    title="play icon designed by brucira"
                  />
                </button>
              </div>
            </div>
            <div className="section features-main" id="features">
              <div className="container">
                <div className="feature-set-single" id="comment-live-website">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <video
                        src="/assets/img/review-static-images.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-5px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Pixel-pinned comments on static images
                      </h2>
                      <p>
                        Share contextual feedback directly on static images. Get
                        changes done on certain sections of an image by
                        commenting on them.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="feature-set-single" id="edit-live-website">
                  <div className="flex flex-wrap align-items-center row-reverse-mob">
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Add video comments to your static designs
                      </h2>
                      <p>
                        Avoid countless iterations by sharing video comments on
                        static images and save time on typing every comment
                        individually.
                      </p>
                    </div>
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/add-video-comments.png"
                          alt="illustration of video comments resolved after done"
                          title="illustration of video comments resolved after done"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="feature-set-single" id="client-commenting">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/resolve-comments.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Resolve comments once the changes are done
                      </h2>
                      <p>
                        Review all the tasks you have assigned through comments.
                        Your teammates can mark them done once the feedback is
                        included.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-get-started">
              <GetStartedCenter toggleSignup={toggleSignup} />
            </div>
            <TestimonialsSlider />

            <section className="usecases-main" id="usecases">
              <div className="container">
                <h2 className="heading" style={{ marginBottom: '25px' }}>
                  ruttl solves a lot of usecases
                </h2>
                {/* <p>
                We built ruttl to be the one-stop solution for your web design
                needs. Here’s a look at the use cases ruttl is perfect for:
              </p> */}

                <div className="flex flex-wrap">
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-designer">
                      <img
                        src="/assets/img/designer.png"
                        alt="ruttl ui ux designer avatar"
                        title="ruttl ui ux designer avatar"
                      />
                      <h3>
                        <span className="blue-text">UI/UX Designer</span>
                        &nbsp;— Get your designs coded pixel perfectly
                      </h3>
                      <p>
                        As a designer, it’s really important that your design
                        and code match perfectly.&nbsp;
                        <Link to="/">ruttl</Link>
                        &nbsp;allows you to edit the staging link so you can
                        share the exact values with the developers to create
                        pixel perfect designs.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>

                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-developer">
                      <img
                        src="/assets/img/developer.png"
                        alt="ruttl developer avatar"
                        title="ruttl developer avatar"
                      />
                      <h3>
                        <span className="blue-text">Developers</span>
                        &nbsp;— Reduce coordination time by collecting exact CSS
                        values
                      </h3>
                      <p>
                        As a developer, you get exact values of the changes
                        needed to be done on the webpage. These changes can be
                        found under the Activity Tab. You can just copy and
                        paste the same on your main source
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single">
                      <img
                        src="/assets/img/client.png"
                        alt="ruttl clients avatar"
                        title="ruttl clients avatar"
                      />
                      <h3>
                        <span className="blue-text">Clients</span>
                        &nbsp;— Give timely feedback, review changes, and see
                        your ideas come to life
                      </h3>
                      <p>
                        Clients are included in every step of the project with
                        ruttl. Through ruttl’s Preview mode, clients can make
                        sure the designs align with their vision and switch to
                        Comment mode to offer clear &amp; contextual feedback
                        for any changes they’d like.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div
                      className="usecase-single"
                      id="usecase-project-manager">
                      <img
                        src="/assets/img/project-manager.png"
                        alt="ruttl project manager avatar"
                        title="ruttl project manager avatar"
                      />
                      <h3>
                        <span className="blue-text">Project managers</span>
                        &nbsp;— Manage multiple web design projects easily with
                        a bird’s eye view of every single activity
                      </h3>
                      <p>
                        With ruttl, project managers can clearly see the
                        progress on a project. All comments and suggestions are
                        in a single spot to guide changes, resolve pain points,
                        and ensure that the project is moving forward at the
                        right pace.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-content-writer">
                      <img
                        src="/assets/img/content-writer.png"
                        alt="ruttl content writer avatar"
                        title="ruttl content writer avatar"
                      />
                      <h3>
                        <span className="blue-text">Content Writers</span>
                        &nbsp; — Visualize how the content looks on the website
                      </h3>
                      <p>
                        As a content writer, you no longer have to use word
                        documents in order to send website copy. Write the
                        content directly on the live website and visualize how
                        it looks with the design. Word limits are a problem no
                        longer!
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  <div className="usecase-block">
                    <div className="usecase-single" id="usecase-agency">
                      <img
                        src="/assets/img/agencies.png"
                        alt="ruttl agencies avatar"
                        title="ruttl agencies avatar"
                      />
                      <h3>
                        <span className="blue-text">Agencies</span>
                        &nbsp;— Work efficiently on multiple project and never
                        miss a deadline
                      </h3>
                      <p>
                        Agencies can work on multiple web projects seamlessly
                        and invite clients to review and provide feedback. This
                        saves a lot of time and money by reducing the
                        coordination hours between developers and designers.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Free Trial
                      </button>
                    </div>
                  </div>
                  {/*  
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-product-team">
                    <img
                      src="/assets/img/product-team.png"
                      alt="ruttl avatar for product teams"
                    />

                    <h3>
                      <span className="blue-text">Product teams</span>
                      &nbsp; — Boost intra-team and inter-team collaboration to
                      release new products, features, and more
                    </h3>
                    <p>
                      Not just websites, ruttl also allows you to test internal
                      tools, admin panels, and all types of web apps. Developers and
                      designers can collaborate effectively to get to perfect
                      results.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-marketing-team">
                    <img
                      src="/assets/img/marketing-team.png"
                      alt="ruttl avatar for marketing teams"
                    />

                    <h3>
                      <span className="blue-text">Marketing teams</span>
                      &nbsp;— Reduce dependency on designers and developers; edit
                      websites without coding knowledge
                    </h3>
                    <p>
                      Try on different banners on your website or change the content
                      as you need without depending on designers and developers.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single" id="usecase-sales-team">
                    <img
                      src="/assets/img/sales-team.png"
                      alt="ruttl avatar for sales teams"
                    />
                    <h3>
                      <span className="blue-text">Sales teams</span>
                      &nbsp;— Create custom sales experiences to increase sales and
                      offer a personal touch to your customers
                    </h3>
                    <p>
                      Quickly change text, images, styling and bring your visions to
                      life. ruttl enables the sales team to customise demos before
                      each meeting, which increases the bottom line conversion rate
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup">
                      Start Free Trial
                    </a>
                  </div>
                </div>
                <div className="usecase-block">
                  <div className="usecase-single">
                    <img src="/assets/img/freelancer.png" alt="Freelancer" />
                    <h3>
                      <span className="blue-text">Freelancers</span>
                      &nbsp;— Independent designers, developers, and content
                      creators can use ruttl to perfect their web projects
                    </h3>
                    <p>
                      ruttl has been designed keeping in mind both teams and
                      individual users. Freelance designers, developers, and writers
                      can use ruttl to review, edit, and build websites, web apps,
                      and more.
                    </p>
                    <a className="button" href="https://web.ruttl.com/signup" className="button">
                      Start Free Trial
                    </a>
                  </div>
                </div> */}
                </div>
                <p style={{ fontSize: '18px', fontWeight: '600' }}>
                  To know about the competitive advanatges of ruttl, check out
                  our
                  <Link to="/alternatives/">&nbsp;alternatives&nbsp;</Link>
                  page.
                </p>
              </div>
            </section>

            <NewFaq limitFaq />
          </main>
        </>
      )}
    </Layout>
  );
};

StaticImageReview.propTypes = {
  location: PropTypes.object,
};

export default StaticImageReview;
